import { BoardTypeInfo } from './board-type';
import { BuildingBlock } from './building-block';
import { Employee } from './employee';
import { LabInfo } from './lab';
import { Person } from './person';
import { RecipeComponent } from './recipe-component';
import { ValidationTeamInfo } from './validation-team';
import { CheckListItem } from './building-block';

export class Recipe {
  id: string = '';
  recipeNumber: string = '';
  recipeName: string = '';
  content: string = '';
  createdBy = new Person;
  createdDate = new Date();
  updatedBy = new Person;
  updatedDate = new Date();
  revision = 0;
  labs: LabInfo[] = [];
  emailDistribution: string[] = [];
  application: string = '';
  owner = new Person;
  coOwner: Person[] = [];
  rampUp: string = '';
  description: string = '';
  modules: SelectedModule[] = [];
  buildingBlocks: SelectedBuildingBlock[] = [];
  boardTypes: BoardTypeInfo[] = [];
  validationTeams: ValidationTeamInfo[] = [];
  status: string = '';
  isArchived = false;
  isTemplate = false;
  isDraft = false;
  parentRecipe = new ParentInfo;
  siliconProgram = '';
  products: string[] = [];
  ownerEmail : string = '';
  ownerName: string ='';
  ownerWwid: string = '';
  allowUpdateBB = true;
  notify = true;

}

export class ParentInfo {
  id: string = '';
  revision: number = -1;
}

export class SelectedModule {
  name: string = '';
  order: number = 0;
}

export class SelectedBuildingBlock {
  buildingBlockId: string = '';
  order: number = 0;
  moduleName: string = '';
  checkList: CheckListItem[] = [];
  progress: string = '';
}
